import React from "react"
import SeO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <React.Fragment>
    <SeO title="404: Η σελίδα δε βρέθηκε" />
    <div className="content">
      <h1>Σφάλμα 404</h1>
      <p>Η σελίδα δε βρέθηκε.</p>
      <Link to="/">Επιστροφή</Link>
    </div>
  </React.Fragment>
)

export default NotFoundPage
